import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import HeroRow from '../components/hero-row'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import './index.css'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout location={this.props.location}>
        <div className="container-fluid">
          <HeroRow text="Blog" subtext="The latest updates from Home Care Suites."/>
          <div className="row">
              {posts.map(({ node }) => {
                return (
                  <div className="col-md-4" key={node.slug}>
                    <ArticlePreview article={node} />
                  </div>
                )
              })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
